import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../store";

/**
 * Authentication state declaration
 */
export interface AuthenticationState {
  isAuthenticated: Boolean;
  accessToken: string;
  user: any;
}

/**
 * Initial authentication state definition
 */
const initialState: AuthenticationState = {
  isAuthenticated: false,
  accessToken: "",
  user: null,
};

/**
 * Authentication slice combining actions and reducer
 */
export const authenticationSlice = createSlice({
  name: "authentication",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    resetAuthentication: (state) => {
      state.accessToken = "";
      state.isAuthenticated = false;
      state.user = null;
    },
    setIsAuthenticated: (state, action: PayloadAction<boolean>) => {
      state.isAuthenticated = action.payload;
    },
    // Use the PayloadAction type to declare the contents of `action.payload`
    setAccessToken: (state, action: PayloadAction<string>) => {
      state.accessToken = action.payload;
    },
  },
});

export const { resetAuthentication, setIsAuthenticated, setAccessToken } =
  authenticationSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectAccessToken = (state: RootState) =>
  state.authentication.accessToken;
export const selectIsAuthenticated = (state: RootState) =>
  state.authentication.isAuthenticated;
export const selectUser = (state: RootState) => state.authentication.user;

export default authenticationSlice.reducer;
