import "../../App.css";
import logo from "../../images/lab55_logo.png";

const Spinner = ({ content }: any) => {
  return (
    <div>
      <div className="d-flex justify-content-center mb-4">
        <img className="mt-2" src={logo} alt="Lab 55000" width={100} />
      </div>
      <div className="d-flex justify-content-center">
        <div className="spinner-border text-secondary" role="status">
          <span className="visually-hidden"></span>
        </div>
        {content && (
          <div>
            <h4 className="ms-3 pb-1">Loading...{content}</h4>
          </div>
        )}
        {!content && (
          <div>
            <h4 className="ms-3 pb-1">Loading...</h4>
          </div>
        )}
      </div>
    </div>
  );
};

export default Spinner;
