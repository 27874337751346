export enum userPermissionType {
  "administrator" = 0,
  "manager" = 1,
  "read_only" = 2,
}

// Forta Date to en-AU
export enum DateFormatOptions {
  enAU = "en-AU",
  enUS = "en-US",
}

export enum FileStorageTypeEnum {
  INSPECTION,
  COMPONENT,
  STRUCTURE,
  FBXMODEL,
  ACTION,
}

export enum OrganisationType {
  global = 1,
  client = 2,
  partner = 3,
}

export enum UserPermissions {
  "GLOBAL_ADMIN" = 1,
  "GLOBAL_MANAGER" = 2,
  "CLIENT_ADMIN" = 3,
  "CLIENT_MANAGER" = 4,
  "TEAM_MEMBER" = 7,
  "READ_ONLY" = 8,
}

export enum UserStatus {
  "ACTIVE" = 0,
  "BLOCKED" = 1,
  "NEW_USER" = 2,
}

export enum StorageURITypes {
  "DOWNLOAD_URI" = 0,
  "UPLOAD_URI" = 1,
  "DELETE_URI" = 2,
}

export enum PersonType{
  "INSPECTOR" = 1,
  "REVIEWER" = 2,
  "ENTEREDBYID"=3
}

export enum HistoryLogActionEnum {
  FILE_OVERWRITE,
  FILE_UPLOAD,
  FILE_SOFT_DELETE,
  FILE_HARD_DELETE,
  SENT_EMAIL_WITH_ATTACHMENTS,
  STRUCTURE_SAVE,
  INSPECTION_ADD,
  COMPONENT_EDIT,
  COMPONENT_ADD,
  COMPONENT_DELETE,
  INSPECTION_DELETE,
  INSPECTION_EDIT,
  STRUCTURE_EDIT,
  INSPECTION_ACTION_ADD,
  INSPECTION_ACTION_EDIT,
  INSPECTION_ACTION_DELETE,
  FBX_UPLOAD,
  STRUCTURE_COMPONENT_ADD,
  STRUCTURE_COMPONENT_EDIT,
  STRUCTURE_COMPONENT_DELETE,
  FBX_DELETE,
  PHOTO_COMPONENT_CHANGE, 
  INSPECTION_ACTION_COMPONENT_DELETE,
  INSPECTION_COMPONENT_DELETE ,
  INSPECTION_COMPONENT_IMAGE_DELETE ,
}


