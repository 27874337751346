import React from "react";
import { useHistory } from "react-router-dom";
import { Auth0Provider } from "@auth0/auth0-react";

interface Auth0MiddlewareProps {
  children: any;
}

const Auth0Middleware: React.FC<Auth0MiddlewareProps> = ({ children }) => {
  const domain: string = process.env.REACT_APP_AUTH0_DOMAIN!;
  const clientId: string = process.env.REACT_APP_AUTH0_CLIENT_ID!;
  const audience: string = process.env.REACT_APP_AUTH0_AUDIENCE!;
  const history = useHistory();

  const onRedirectCallback = (appState: any) => {
    history.push(appState?.returnTo || window.location.pathname);
  };

  return (
    <>
      <Auth0Provider
        domain={domain}
        clientId={clientId}
        redirectUri={window.location.origin + "/home"}
        onRedirectCallback={onRedirectCallback}
        audience={audience}
        useRefreshTokens={true}
        cacheLocation="localstorage"
      >
        {/* Components using Auth0 */}
        {children}
      </Auth0Provider>
    </>
  );
};

export default Auth0Middleware;
