import { Link } from "react-router-dom";
import "../../App.css";
import React from "react";

const SpinnerWithoutLogo = ({ message }: any) => {
	let messageToShow = message;
	if (message === undefined) {
		messageToShow = "Loading...";
	}
	return (
		<div>
			<div className="d-flex justify-content-center">
				<div className="spinner-border text-info" role="status">
					<span className="visually-hidden"></span>
				</div>
				<div>
					<h4 className="ms-3 pb-1">{messageToShow}</h4>
				</div>
			</div>
		</div>
	);
};

export default SpinnerWithoutLogo;
