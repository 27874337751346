import { useAuth0 } from "@auth0/auth0-react";
import React, { lazy } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import ProtectedRoute from "../middleware/ProtectedRoute";
import { ShowLoading } from "../utilities/functions";


const LandingPage = lazy(() => import("../pages/LandingPage/LandingPage"));
const ErrorPermissionsPage = lazy(() => import("../pages/ErrorPermissionPage/ErrorPermissionPage"));
const HomePage = lazy(() => import("../pages/HomePage/homePage"));
const HomePagev2 = lazy(() => import("../pages/HomePage/HomePageV2"));
const OrganisationsPage = lazy(() => import("../pages/OrganisationsPage/OrganisationsPage"));
const UsersPage = lazy(() => import("../pages/UsersPage/UsersPage"));
const UserProfilePage = lazy(() => import("../pages/UserProfilePage/UserProfilePage"));
const NewStructurePage = lazy(() => import("../pages/NewStructurePage/NewStructurePage"));
const NewStructurePagev2 = lazy(() => import("../pages/NewStructurePage/NewStructurePageV2"));
const NewInspectionPage = lazy(() => import("../pages/InspectionPages/NewInspectionPage"));
const NewInspectionPageV2 = lazy(() => import("../pages/InspectionPages/NewInspectionPageV2"));
const structureDetailsPage = lazy(() => import("../pages/StructurePages/StructureDetails"));
const structureDetailsPagev2 = lazy(() => import("../pages/StructurePages/StructureDetailsV2"));
const InspectionDetailsPage = lazy(() => import("../pages/InspectionPages/InspectionDetailsPage"));
const InspectionDetailsPageV2 = lazy(() => import("../pages/InspectionPages/InseptionDetailsPageV2"));
const SettingsPage = lazy(() => import("../pages/SettingsPage/SettingsPage"));
const ExportInspectionPage = lazy(() => import("../pages/ExportInspectionPage/ExportInspectionPage"));
const ExportInspectionPageV2 = lazy(() => import("../pages/ExportInspectionPage/ExportInspectionPageV2"));
const NotFoundPage = lazy(() => import("../pages/NotFoundPage/NotFoundPage"));
const ErrorAccountValidation = lazy(() => import("../pages/VerifyAccountPage/verifyAccountPage"));

// Session control variables
const MAX_SESSION_TIME = 360; // Minutes
let count = 0;

const AppRoutes = () => {
  const { user, isAuthenticated, isLoading, logout, loginWithRedirect } = useAuth0();

  // Redirect user to Auth0 for authentication
  async function LogInUser() {
    await loginWithRedirect();
  }

  // Log out user and clean session and local storages
  async function LogOutUser() {
    sessionStorage.clear();
    localStorage.clear();
    logout({ returnTo: process.env.REACT_APP_FRONTEND_BASE_URL + "/home" });
  }

  // Calculates the time elapsed of the session
  function SessionTimeExpired(time: any) {
    var startDate = new Date(Number(time));
    var endDate = new Date();
    var seconds = (endDate.getTime() - startDate.getTime()) / 1000;
    var minutes = seconds / 60;
    return minutes > MAX_SESSION_TIME;
  }

  //  Logs out user if he/she closes the tab or the browser
  if (!localStorage.isAuthenticated) {
    LogOutUser();
  }

  // Log out the user if the SESSION_TIME expire
  if (localStorage.isAuthenticated && count === 0) {
    let date = localStorage.getItem("time");
    if (!date || SessionTimeExpired(date)) {
      LogOutUser().then(() => {
        setTimeout(() => {
          LogInUser();
        }, 200);
      });
    }
    count++;
  }

  // Show while waiting for Auth0 authentication
  if (isLoading) {
    return <div className="mt-5 pt-5">{ShowLoading()}</div>;
  }

  // Set variables for the session
  if (!localStorage.isAuthenticated) {
    localStorage.setItem("isAuthenticated", "true");
    localStorage.setItem("time", new Date().getTime().toString());
  }

  // If user is registered in Auth0 but email was not verified
  if (isAuthenticated && !user?.email_verified) {
    return (
      <Switch>
        <Redirect path="/home" exact={true} to="/" />;
        <ProtectedRoute path="/errorAccountValidation" exact={true} component={ErrorAccountValidation} />
        <Redirect to="/errorAccountValidation" />
      </Switch>
    );
  }

  // If user is authenticated by Auth0
  else if (isAuthenticated) {
    return (
      <Switch>
        <Redirect path="/" exact={true} to="/home" />;
        <ProtectedRoute path="/home" exact={true} component={HomePage} />
        <ProtectedRoute path="/homev2" exact={true} component={HomePagev2} />
        <ProtectedRoute path="/organisations" exact component={OrganisationsPage} />
        <ProtectedRoute path="/users" exact={true} component={UsersPage} />
        <ProtectedRoute path="/profile" exact={true} component={UserProfilePage} />
        <ProtectedRoute path="/settings" exact={true} component={SettingsPage} />
        <ProtectedRoute path="/newStructure" exact={true} component={NewStructurePage} />
        <ProtectedRoute path="/newStructurev2" exact={true} component={NewStructurePagev2} />
        <ProtectedRoute path="/structure/:structureId/newInspection" exact component={NewInspectionPage} />
        <ProtectedRoute path="/structurev2/:structureId/newInspectionV2" exact component={NewInspectionPageV2} />
        <ProtectedRoute path="/structure/:structureId" exact component={structureDetailsPage} />
        <ProtectedRoute path="/structurev2/:structureId" exact component={structureDetailsPagev2} />
        <ProtectedRoute path="/structure/:structureId/:inspectionId" exact component={InspectionDetailsPage} />
        <ProtectedRoute path="/structurev2/:structureId/:inspectionId" exact component={InspectionDetailsPageV2} />
        <ProtectedRoute path="/export/:structureId/:inspectionId" exact component={ExportInspectionPage} />
        <ProtectedRoute path="/exportv2/:structureCode/:structureId/:inspectionId" exact component={ExportInspectionPageV2} />
        <Route path="/errorPermission" exact={true} component={ErrorPermissionsPage} />
      </Switch>
    );
  }

  // If user is not authenticated by Auth0
  else if (!isAuthenticated) {
    return (
      <Switch>
        <Redirect path="/home" exact={true} to="/" />;
        <Route path="/" exact={true} component={LandingPage} />
        <Route path="*" component={NotFoundPage} />
      </Switch>
    );
  } else {
    return null;
  }
};
export default AppRoutes;
