import React from "react";
import { Route } from "react-router-dom";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import { ShowLoading } from "../utilities/functions";

const ProtectedRoute: React.FC<any> = ({ component, ...args }) => (
	<Route
		component={withAuthenticationRequired(component, {
			onRedirecting: () => <div>{ShowLoading()}</div>,
		})}
		{...args}
	/>
);

export default ProtectedRoute;
