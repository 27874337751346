import { configureStore } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import authenticationReducer from "./features/authentication/authenticationSlice";
import { setupListeners } from "@reduxjs/toolkit/dist/query/react";
import { devToolsEnhancer } from "@reduxjs/toolkit/dist/devtoolsExtension";

/**
 * AirspaceLab Redux Store using Redux toolkit
 *
 * https://redux-toolkit.js.org/tutorials/typescript
 * https://redux-toolkit.js.org/usage/usage-guide
 * https://redux-toolkit.js.org/api/createAsyncThunk
 * https://redux-toolkit.js.org/usage/usage-with-typescript#type-safety-with-extrareducers
 *
 */

export const store = configureStore({
  reducer: {
    authentication: authenticationReducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware(),
  devTools: true,
});

// optional, but required for refetchOnFocus/refetchOnReconnect behaviors
// see `setupListeners` docs - takes an optional callback as the 2nd arg for customization
setupListeners(store.dispatch);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;

export default store;
