import { useAuth0 } from "@auth0/auth0-react";
import React, { useEffect } from "react";
import {
  resetAuthentication,
  setAccessToken,
} from "../store/features/authentication/authenticationSlice";
import { useAppDispatch } from "../store/hooks";

interface AuthenticationMiddlewareProps {
  children?: any;
}

const AuthenticationMiddleware: React.FC<AuthenticationMiddlewareProps> = ({
  children,
}) => {
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (isAuthenticated) {
      getAccessTokenSilently().then((token) => dispatch(setAccessToken(token)));
    } else {
      dispatch(resetAuthentication());
    }
  }, [isAuthenticated]);
  return <>{children}</>;
};

export default AuthenticationMiddleware;
